
import { defineComponent, reactive, ref } from 'vue';

import SVG from '@/components/SVG.vue';
import Loader from '@/components/Loader.vue';
import Input from '@/components/form/Input.vue';
import AuthService from '@/services/auth.service';

export default defineComponent({
	components: {
		SVG,
		Loader,
		Input,
	},

	setup() {
		const loading = ref(false);
		const success = ref(false);

		const form = reactive({
			email: '',
		});

		async function submit() {
			if (!form.email.length) {
				return;
			}

			loading.value = true;

			await AuthService.forgotPassword(form.email)
				.then((response) => {
					if (response) {
						success.value = true;
					}
				})
				.finally(() => {
					loading.value = false;
				});
		}

		return {
			// data
			form,
			loading,
			success,

			// functions
			submit,
		};
	},
});
